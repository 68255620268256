import React, { useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import ThumbGrid from "./Thumbnails"
import LightBox from "./Lightbox"

const GalleriStilleben = () => {
  const [showLightbox, setShowLightbox] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  const handleOpen = i => () => {
    setShowLightbox(true)

    setSelectedImage(i)
    console.log("Second:" + showLightbox)
    console.log(selectedImage)
  }
  const handleClose = () => {
    setShowLightbox(false)
    setSelectedImage(null)
  }
  const handlePrevRequest = (i, length) => () => {
    setSelectedImage((i - 1 + length) % length)
  }
  const handleNextRequest = (i, length) => () => {
    setSelectedImage((i + 1) % length)
  }
  return (
    <StaticQuery
      query={graphql`
        {
          wpPage(databaseId: { eq: 494 }) {
            id
            bilder {
              bild {
                caption
                bildfil {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 70)
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const images = data.wpPage.bilder.bild
        return (
          <div className="">
            <ThumbGrid images={images} handleOpen={handleOpen} />

            {showLightbox && selectedImage !== null && (
              <LightBox
                images={images}
                handleClose={handleClose}
                handleNextRequest={handleNextRequest}
                handlePrevRequest={handlePrevRequest}
                selectedImage={selectedImage}
              />
            )}
          </div>
        )
      }}
    />
  )
}
export default GalleriStilleben
