import React from "react"
import GalleriStilleben from "../components/bilder/GalleriStilleben"

import Layout from "../components/layout"
import SEO from "../components/seo"

function MatPage() {
  return (
    <Layout>
      <SEO
        keywords={[`Jens`, `Nordström`, `Fotograf`, `Malmö`]}
        title="Stilleben &amp; platser"
      />

      <section className="">
        <GalleriStilleben />
      </section>
    </Layout>
  )
}

export default MatPage
